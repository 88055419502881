import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import ProductCard from '../ProductCard';
import { CampaignStatus } from '../../types/common';
import { CaptionStyles, S1Styles, H5, P1 } from '../Typography';

const ProductContents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border-radius: 12px;
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 9)};
  padding: 20px 0px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-left: 0px;
    padding: 20px 0px;
  }
`;

const TitleWrapper = styled.div`
  margin-left: 12px;
  margin-bottom: 15px;
`;

const ProductWrapper = styled.div<{ hasJustOneProduct: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(30%), 1fr));
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: ${({ hasJustOneProduct }) => (hasJustOneProduct ? 'block' : 'grid')};
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

const CampaignStatusTitle = styled(H5)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    ${S1Styles}
  }
`;

const CampaignStatusDescription = styled(P1)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    ${CaptionStyles}
  }
`;

type CreatorProductsProps = {
  products: Shopify.ProductEdge[];
  campaignStatus: CampaignStatus;
  creatorName: string;
  isCreatorBrandedPage: boolean;
};

const CreatorProducts: React.FC<CreatorProductsProps> = ({
  products,
  campaignStatus,
  creatorName,
  isCreatorBrandedPage,
}: CreatorProductsProps) => {
  const renderCampaignStatus = (status: CampaignStatus) => {
    if (status === CampaignStatus.Live) {
      return (
        <>
          <CampaignStatusTitle>Live</CampaignStatusTitle>
          <CampaignStatusDescription>Check out what&apos;s new from {creatorName}! 🚀</CampaignStatusDescription>
        </>
      );
    }
    if (status === CampaignStatus.ComingSoon) {
      return (
        <>
          <CampaignStatusTitle>Coming Soon</CampaignStatusTitle>
          <CampaignStatusDescription>
            Get a sneak peek at what&apos;s next for {creatorName}! 👀
          </CampaignStatusDescription>
        </>
      );
    }
    if (status === CampaignStatus.Past) {
      return (
        <>
          <CampaignStatusTitle>Sold Out</CampaignStatusTitle>
          <CampaignStatusDescription>Check out the previous drops from {creatorName}!</CampaignStatusDescription>
        </>
      );
    }
  };

  if (products.length === 0) {
    return null;
  }
  return (
    <ProductContents>
      <TitleWrapper>{renderCampaignStatus(campaignStatus)}</TitleWrapper>
      <ProductWrapper hasJustOneProduct={products.length === 1}>
        {products.map(({ node }) => (
          <ProductCard key={node.id} product={node} isOnCreatorCollectionPage isCreatorBranded={isCreatorBrandedPage} />
        ))}
      </ProductWrapper>
    </ProductContents>
  );
};

export default CreatorProducts;
