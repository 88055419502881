import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Flickity, { FlickityOptions } from 'react-flickity-component';
import posthog from 'posthog-js';

interface FlickityExtended extends Flickity {
  slider?: HTMLElement;
}

const StyledFlickity = styled(Flickity)<{
  showArrow: boolean;
}>`
  width: 282px;
  .flickity-prev-next-button {
    display: ${({ showArrow }) => (showArrow ? 'block' : 'none')} !important;
  }

  .flickity-prev-next-button.previous {
    left: unset !important;
    right: unset !important;
  }

  .flickity-prev-next-button.next {
    right: 0 !important;
    left: unset !important;
  }
  .flickity-page-dots {
    bottom: 8px !important;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 354px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 376px;
  }
`;
const CarouselWrapper = styled.div``;

type BannerProps = {
  children: JSX.Element[];
};

const GTShopMastheadCarousel: React.FC<BannerProps> = ({ children }: BannerProps) => {
  const flickityRef = useRef<FlickityExtended>();

  const flickityOptions: FlickityOptions = {
    initialIndex: 0,
    draggable: children.length > 1,
    cellAlign: 'center',
    autoPlay: 5000,
    wrapAround: true,
  };

  const handleDragStart = () => {
    flickityRef.current?.slider?.childNodes.forEach((slide) => {
      const currentSlide = slide as HTMLDivElement;
      currentSlide.style.pointerEvents = 'none';
    });
  };

  const handleDragEnd = () => {
    flickityRef.current?.slider?.childNodes.forEach((slide) => {
      const currentSlide = slide as HTMLDivElement;
      currentSlide.style.pointerEvents = 'all';
    });
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.on('dragStart', handleDragStart);
      flickityRef.current.on('dragEnd', handleDragEnd);
    }
    return () => {
      if (flickityRef.current) {
        flickityRef.current.off('dragStart', handleDragStart);
        flickityRef.current.off('dragEnd', handleDragEnd);
      }
    };
  }, []);

  const handleOnChange = () => {
    posthog.capture(`custom_creator_shop_carousel_swipe`, {
      selected_slide_index: flickityRef.current?.selectedIndex,
      event_location: 'custom_creator_shop_image_carousel',
      total_slide_count: children?.length,
    });
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.on('change', handleOnChange);
    }
    return () => {
      if (flickityRef.current) {
        flickityRef.current.off('change', handleOnChange);
      }
    };
  }, [children]);

  const cardsArray = children.map((card, i) => ({ key: i, value: card }));

  return (
    <StyledFlickity
      flickityRef={(ref) => {
        flickityRef.current = ref;
      }}
      elementType="div"
      options={{ ...flickityOptions, prevNextButtons: children.length > 1 }}
      showArrow
    >
      {cardsArray.map((child) => (
        <CarouselWrapper key={child.key}>{child.value}</CarouselWrapper>
      ))}
    </StyledFlickity>
  );
};
export default GTShopMastheadCarousel;
