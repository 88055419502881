import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps } from 'next';

import { Button, utils } from '@makeship/core';
import dynamic from 'next/dynamic';
import Head from '../../../components/Head';
import { H5, S2 } from '../../../components/Typography';

import {
  filterProducts,
  getSortedProducts,
  isCreatorMessageEligible,
  parseCollectionMetafields,
} from '../../../utils/collections';

import { getCollectionByHandle } from '../../../api/collections';
import { useFetchSalesQuantityForGT } from '../../../hooks/useFetchInterval';

import { getPropsForPageLoad } from '../../../utils/pageLoad';
import CreatorMessage from '../../../components/pages/CustomCreatorCollection/CreatorMessage';
import StretchGoalsProgressBar from '../../../components/pages/CustomCreatorCollection/StretchGoalsProgressBar';
import StretchGoalsRewardList, { Goal } from '../../../components/pages/CustomCreatorCollection/StretchGoalsRewardList';
import { useNavTheme } from '../../../context/theme';
import { NavTheme, CampaignStatus } from '../../../types/common';
import { trackSubscribeButtonClicked } from '../../../utils/analytics';
import { useStore } from '../../../store';
import CreatorProducts from '../../../components/CreatorProducts';
import CustomCreatorCollectionHeader from '../../../components/pages/CustomCreatorCollection/CustomCreatorHeader';

import CompleteCollectionComponent from '../../../components/pages/products/CompleteCollectionComponent';
import { VariantSelectionError } from '../../../types/errors';
import GTShopMasthead from '../../../components/pages/CustomCreatorCollection/GTShopMasthead';

const LazySubscribeModal = dynamic(() => import('../../../components/Modal/SubscribeModal'), { ssr: false });

const ShopPageWrapper = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.desktop}px;
  margin: 0 auto;
  padding: 48px 16px;
`;

const SearchQuery = styled(H5)`
  margin-left: 12px;
  font-size: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    font-size: 36px;
  }
`;

const MediaEmbed = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom 36px;

  border-radius: 0 12px 12px 12px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => theme.colors.neutral7};

  iframe,
  img,
  figure,
  video {
    border-radius: inherit;
    display: block;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    aspect-ratio: 4 / 3;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-bottom: 84px;
  }
`;

const SubscribeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 8px 12px 8px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 54px;
  }
`;

const SubscribeContainer = styled.div<{ isCreatorBranded?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 12px;
  gap: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  ${({ isCreatorBranded }) => isCreatorBranded && `border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));`}
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 558px;
  }
`;

const SubscribeButton = styled(Button.Primary)<{ isCreatorBranded?: boolean }>`
  width: 100%;
  padding: 14px 16px;
  ${({ isCreatorBranded, theme }) =>
    isCreatorBranded &&
    `
      background: ${theme.colors.neutral7};
      &:hover {
        background: ${theme.colors.neutral6};
      }
  `}
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 14px 24px;
  }
`;

const SubscribeText = styled(S2)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 18px;
  }
`;

const GTShopMastheadWrapper = styled.div`
  margin: 24px 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 36px;
  }
`;

const CreatorProductsWrapper = styled.div<{ isCreatorBranded: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 84px;
  }
`;

const CreatorProductsDisplayWrapper = styled.div`
  margin-bottom: 36px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-bottom: 84px;
  }
`;

const CompleteCollectionAndMessageAndStretchGoalsWrapper = styled.div<{
  isCreatorMessageEligible: boolean;
  isCompleteCollectionEligible: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    justify-content: ${({ isCreatorMessageEligible }) => (isCreatorMessageEligible ? `space-between` : `center`)};
    flex-direction: row;
    gap: 36px;
    align-items: start;
    margin-bottom: ${({ isCompleteCollectionEligible }) => (isCompleteCollectionEligible ? `84px` : `0px`)};
  }
`;

const CreatorMessageWrapperAndStretchGoalsWrapper = styled.div<{
  isCreatorMessageEligible: boolean;
  shouldShowStretchGoals: boolean;
  hasLiveProducts: boolean;
}>`
  display: flex;
  align-items: ${({ isCreatorMessageEligible }) => (isCreatorMessageEligible ? `start` : `center`)};
  flex-direction: column-reverse;

  @media screen and (min-width: 600px) {
    align-items: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
    max-width: ${({ shouldShowStretchGoals, hasLiveProducts }) =>
      shouldShowStretchGoals && hasLiveProducts ? `400px` : `719px`};
    gap: 0px;
  }
`;

const CompleteCollectionWrapper = styled.div<{
  isCreatorMessageEligible: boolean;
  isCreatorBranded: boolean | undefined;
}>`
  width: 100%;
  margin-top: ${({ isCreatorBranded }) => (isCreatorBranded ? `12px` : `0px`)};
  order: 1;

  @media screen and (min-width: 600px) {
    width: 582px;
    max-width: 582px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${({ isCreatorMessageEligible }) => (isCreatorMessageEligible ? `width: 100%` : `width: 582px`)};
    border-radius: 12px;
    margin-top 0;
    border: 2px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, theme.alpha.light)};
    order: 0;
  }
`;

const CreatorMessageWrapper = styled.div`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: block;
    max-width: 719px;
  }
`;

const MobileCreatorMessageWrapper = styled.div`
  order: 2;
  max-width: 719px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`;

const StretchGoalsWrapper = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  border-radius: 12px;
  margin-bottom: 26px;
  max-width: 390px;
  min-width: 328px;
  padding: 30px 8px 18px 8px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 26px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin: 0;
  }
`;

type Response = {
  title: string;
  shouldShowStretchGoals: boolean;
  milestones: number[];
  goals: Goal[];
};

const collectionGoalsMap: Record<string, Response> = {
  'gid://shopify/Collection/298420043932': {
    title: 'Gorilla Tag Birthday Rewards 🎉',
    shouldShowStretchGoals: true,
    milestones: [200, 10000, 30000, 50000],
    goals: [
      {
        amount: 200,
        title: 'In-game Banana Party Hat 🎮 (Codes sent March 7th)',
        emoji: '',
        image: '',
      },
      {
        amount: 10000,
        title: 'Holographic Sticker',
        emoji: '✨',
        image: '/assets/stretch-goals/gt-reward-1.png',
      },
      {
        amount: 30000,
        title: 'Holographic Trading Card',
        emoji: '🎴',
        image: '/assets/stretch-goals/gt-reward-2.png',
        additionalText: ' (5 different cards, 1 per purchase)',
      },
      {
        amount: 50000,
        title: 'Banana Hat Plushie Accessory*',
        emoji: '🥳',
        image: '/assets/stretch-goals/gt-reward-3.png',
        additionalText: ' (*fits Plushie and Doughboi)',
      },
    ],
  },
  // staging GT collection
  'gid://shopify/Collection/296209449099': {
    title: 'Gorilla Tag Birthday Rewards 🎉',
    shouldShowStretchGoals: true,
    milestones: [200, 10000, 30000, 50000],
    goals: [
      {
        amount: 200,
        title: 'In-game Banana Party Hat 🎮 (Codes sent March 7th)',
        emoji: '',
        image: '',
      },
      {
        amount: 10000,
        title: 'Holographic Sticker',
        emoji: '✨',
        image: '/assets/stretch-goals/gt-reward-1.png',
      },
      {
        amount: 30000,
        title: 'Holographic Trading Card',
        emoji: '🎴',
        image: '/assets/stretch-goals/gt-reward-2.png',
        additionalText: ' (5 different cards, 1 per purchase)',
      },
      {
        amount: 50000,
        title: 'Banana Hat Plushie Accessory*',
        emoji: '🥳',
        image: '/assets/stretch-goals/gt-reward-3.png',
        additionalText: ' (*fits Plushie and Doughboi)',
      },
    ],
  },
};

export const getCollectionGoals = (collectionId: string): Response => {
  const goals = collectionGoalsMap[collectionId];
  if (!goals) {
    return {
      title: '',
      shouldShowStretchGoals: false,
      milestones: [],
      goals: [],
    };
  }
  return goals;
};

export type CreatorPageProps = {
  creatorCollection: Shopify.Collection;
  collectionHandle: string;
  isPreview?: boolean | undefined;
};

const CreatorPage: NextPage<CreatorPageProps> = ({
  creatorCollection,
  collectionHandle,
  isPreview,
}: CreatorPageProps) => {
  const [pageProducts, setPageProducts] = useState<Shopify.ProductEdge[]>([]);
  const [livePageProducts, setLivePageProducts] = useState<Shopify.ProductEdge[]>([]);
  const [pinAddOn, setPinAddOn] = useState<Shopify.Product | null>(null);
  const completeTheCollectionRef = useRef<HTMLDivElement>(null);
  const [selectedVariant, setSelectedVariant] = useState<Shopify.ProductVariant>();
  const [selectedHoodieVariants, setSelectedHoodieVariants] = useState<Shopify.ProductVariant[]>([]);
  const [errors, setErrors] = useState<VariantSelectionError[]>([]);

  const { handleNavThemeChange, handleBackgroundColourChange } = useNavTheme();

  const { id, metafields } = creatorCollection;

  const { title, shouldShowStretchGoals, milestones, goals } = getCollectionGoals(id);

  const {
    navTheme,
    isCreatorBranded,
    creatorMessage,
    creatorAvatarURL,
    creatorLogoURL,
    backgroundColour,
    mediaEmbed,
    headerBackgroundURL,
    previewNavTheme,
    previewCreatorMessage,
    previewCreatorAvatarURL,
    previewCreatorLogoURL,
    previewMediaEmbed,
    previewBackgroundColour,
    cycPromoMessage,
  } = parseCollectionMetafields(metafields);

  const hasLiveProducts = creatorCollection.products.edges.some((product) => product.node.tags.includes('live'));
  const { state } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { gtSalesQuantity } = useFetchSalesQuantityForGT(shouldShowStretchGoals);

  const defaultSubheading = `Shop official products from ${creatorCollection.title} exclusively on Makeship`;

  useEffect(() => {
    if (isCreatorBranded || previewNavTheme) {
      if (isPreview) {
        handleNavThemeChange(previewNavTheme as NavTheme);
        handleBackgroundColourChange(previewBackgroundColour);
      }
      if (isCreatorBranded) {
        handleNavThemeChange(navTheme as NavTheme);
        handleBackgroundColourChange(backgroundColour);
      }
    }
    return () => {
      handleNavThemeChange(NavTheme.default);
      handleBackgroundColourChange(undefined);
    };
  }, [
    creatorCollection,
    handleNavThemeChange,
    navTheme,
    isCreatorBranded,
    backgroundColour,
    handleBackgroundColourChange,
    previewNavTheme,
    previewBackgroundColour,
    isPreview,
  ]);

  useEffect(() => {
    setPageProducts(creatorCollection.products.edges);
    setLivePageProducts(
      getSortedProducts(
        filterProducts(creatorCollection.products.edges.filter((product) => product.node.tags.includes('live'))),
      ),
    );
    setPinAddOn(
      creatorCollection.products.edges.find(
        (product) => product.node.tags.includes('pinaddon-pinproduct') && product.node.tags.includes('live'),
      )?.node || null,
    );
  }, [creatorCollection]);

  const campaignStatuses = [
    { tag: 'comingsoon', status: CampaignStatus.ComingSoon },
    { tag: 'past', status: CampaignStatus.Past },
  ];

  const filteredProducts = (tag: string) =>
    getSortedProducts(
      filterProducts(creatorCollection.products.edges.filter((product) => product.node.tags.includes(tag))),
    );

  return (
    <>
      <Head
        title={`${creatorCollection.title}`}
        url={`/shop/creator/${collectionHandle}`}
        description={creatorCollection?.description || defaultSubheading}
        image={creatorCollection?.image?.transformedSrc}
      />
      {((isCreatorBranded && headerBackgroundURL && creatorLogoURL) || isPreview) && (
        <CustomCreatorCollectionHeader creatorCollection={creatorCollection} isPreview={isPreview} />
      )}
      {collectionHandle.includes('gorilla-tag') && (isCreatorBranded || isPreview) && hasLiveProducts && (
        <GTShopMastheadWrapper>
          <GTShopMasthead />
        </GTShopMastheadWrapper>
      )}

      <ShopPageWrapper>
        {(!isCreatorBranded || !headerBackgroundURL || !creatorLogoURL) && !isPreview && (
          <div>
            <SearchQuery data-testid="creator-collection-title">{creatorCollection.title}</SearchQuery>
          </div>
        )}
        {hasLiveProducts && (
          <CreatorProductsDisplayWrapper>
            <CreatorProducts
              products={filteredProducts('live')}
              campaignStatus={CampaignStatus.Live}
              creatorName={creatorCollection.title}
              isCreatorBrandedPage={isCreatorBranded ?? false}
            />
          </CreatorProductsDisplayWrapper>
        )}
        <CompleteCollectionAndMessageAndStretchGoalsWrapper
          isCreatorMessageEligible={isCreatorMessageEligible(
            isCreatorBranded,
            creatorMessage,
            hasLiveProducts,
            isPreview,
            previewCreatorMessage,
          )}
          isCompleteCollectionEligible={hasLiveProducts && (livePageProducts.length > 1 || !!pinAddOn)}
        >
          {hasLiveProducts && (livePageProducts.length > 1 || pinAddOn) && (
            <CompleteCollectionWrapper
              isCreatorBranded={isCreatorBranded}
              isCreatorMessageEligible={isCreatorMessageEligible(
                isCreatorBranded,
                creatorMessage,
                hasLiveProducts,
                isPreview,
                previewCreatorMessage,
              )}
            >
              <CompleteCollectionComponent
                product={undefined}
                pinAddOnProduct={pinAddOn}
                metafields={metafields}
                productsBySameCreator={livePageProducts}
                productsInSameMakeshiftCollection={[]}
                stage={undefined}
                makeshiftCollectionTitle=""
                completeTheCollectionRef={completeTheCollectionRef}
                setSelectedVariant={setSelectedVariant}
                selectedHoodieVariants={selectedHoodieVariants}
                setSelectedHoodieVariants={setSelectedHoodieVariants}
                errors={errors}
                setErrors={setErrors}
                creatorName={creatorCollection.title}
                isCreatorBranded={isCreatorBranded}
                isGTReward={shouldShowStretchGoals}
                cycPromoMessage={cycPromoMessage}
                gtStretchGoalsMet={(gtSalesQuantity?.quantity || 0) >= milestones[milestones.length - 1]}
              />
            </CompleteCollectionWrapper>
          )}
          <CreatorMessageWrapperAndStretchGoalsWrapper
            isCreatorMessageEligible={isCreatorMessageEligible(
              isCreatorBranded,
              creatorMessage,
              hasLiveProducts,
              isPreview,
              previewCreatorMessage,
            )}
            shouldShowStretchGoals={shouldShowStretchGoals}
            hasLiveProducts={hasLiveProducts}
          >
            {shouldShowStretchGoals && gtSalesQuantity && hasLiveProducts && (
              <StretchGoalsWrapper>
                <StretchGoalsProgressBar totalUnitsSold={gtSalesQuantity?.quantity} milestones={milestones} />
                <StretchGoalsRewardList title={title} goals={goals} totalUnitsSold={gtSalesQuantity?.quantity} />
              </StretchGoalsWrapper>
            )}
            {isCreatorMessageEligible(
              isCreatorBranded,
              creatorMessage,
              hasLiveProducts,
              isPreview,
              previewCreatorMessage,
            ) && (
              <CreatorMessageWrapper>
                <CreatorMessage
                  collectionTitle={creatorCollection.title}
                  creatorMessage={isPreview ? previewCreatorMessage || '' : creatorMessage || ''}
                  creatorAvatarURL={isPreview ? previewCreatorAvatarURL || '' : creatorAvatarURL || ''}
                  creatorLogoURL={isPreview ? previewCreatorLogoURL || '' : creatorLogoURL || ''}
                />
              </CreatorMessageWrapper>
            )}
          </CreatorMessageWrapperAndStretchGoalsWrapper>
          {isCreatorMessageEligible(
            isCreatorBranded,
            creatorMessage,
            hasLiveProducts,
            isPreview,
            previewCreatorMessage,
          ) && (
            <MobileCreatorMessageWrapper>
              <CreatorMessage
                collectionTitle={creatorCollection.title}
                creatorMessage={isPreview ? previewCreatorMessage || '' : creatorMessage || ''}
                creatorAvatarURL={isPreview ? previewCreatorAvatarURL || '' : creatorAvatarURL || ''}
                creatorLogoURL={isPreview ? previewCreatorLogoURL || '' : creatorLogoURL || ''}
              />
            </MobileCreatorMessageWrapper>
          )}
        </CompleteCollectionAndMessageAndStretchGoalsWrapper>
        {!hasLiveProducts && !isPreview && (
          <>
            <SubscribeWrapper>
              <SubscribeContainer isCreatorBranded={isCreatorBranded}>
                <SubscribeText>
                  Never miss another {creatorCollection.title && `${creatorCollection.title}`} product launch again! 🚀
                </SubscribeText>
                <SubscribeButton
                  isCreatorBranded={isCreatorBranded}
                  onClick={() => {
                    setIsModalOpen(true);
                    trackSubscribeButtonClicked(undefined, state.user, 'Subscribe For Creator Updates');
                  }}
                >
                  Subscribe For Creator Updates
                </SubscribeButton>
              </SubscribeContainer>
            </SubscribeWrapper>
            <LazySubscribeModal
              isOpen={isModalOpen}
              closeModal={() => setIsModalOpen(false)}
              tags={`subscribed-creator, ${creatorCollection.title}`}
              description={`Don't miss out on future product launches from ${creatorCollection.title}!`}
              title="Subscribe for Creator Updates"
            />
          </>
        )}
        {isCreatorBranded && !isPreview && hasLiveProducts && mediaEmbed && (
          <MediaEmbed
            dangerouslySetInnerHTML={{
              __html: mediaEmbed,
            }}
          />
        )}
        {isPreview && previewMediaEmbed && (
          <MediaEmbed
            dangerouslySetInnerHTML={{
              __html: previewMediaEmbed,
            }}
          />
        )}
        <CreatorProductsWrapper isCreatorBranded={!!isCreatorBranded}>
          {campaignStatuses.map(({ tag, status }, index) => {
            if (filteredProducts(tag).length === 0) {
              return <></>;
            }
            return (
              <>
                <CreatorProductsDisplayWrapper key={status}>
                  <CreatorProducts
                    key={status}
                    products={filteredProducts(tag)}
                    campaignStatus={status}
                    creatorName={creatorCollection.title}
                    isCreatorBrandedPage={isCreatorBranded ?? false}
                  />
                </CreatorProductsDisplayWrapper>
              </>
            );
          })}
        </CreatorProductsWrapper>
      </ShopPageWrapper>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<CreatorPageProps> = async (ctx) => {
  const { params } = ctx;
  const collectionHandle = params ? (params.handle as string) : '';
  const numberOfCollectionProducts = 50;

  const creatorCollection = await getCollectionByHandle(collectionHandle, undefined, 1, numberOfCollectionProducts);
  if (collectionHandle === '' || creatorCollection === null) {
    return {
      notFound: true,
    };
  }

  const propsForPageLoad = await getPropsForPageLoad(ctx);

  return {
    props: {
      creatorCollection,
      collectionHandle,
      ...propsForPageLoad,
    },
  };
};

export default CreatorPage;
