import React from 'react';
import Image from 'next/image';
import posthog from 'posthog-js';
import styled from 'styled-components';
import { Caption, S1Styles, S2Styles, P2, P1Styles, S1, S2, S3 } from '../../../Typography';
import Container from '../../../Container';
import GTShopMastheadCarousel from './GTShopMastheadCarousel';

const MastheadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-image: url('/assets/gt/GT_Masthead-Mobile.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 524px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-image: url('/assets/gt/GT_Masthead-Tablet.png');
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    background-image: url('/assets/gt/GT_Masthead-Desktop.png');
    height: 423px;
  }
`;

const MastheadContainer = styled(Container)`
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0 16px;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: row;
    align-items: center;
    width: 750px;
  }
`;

const InfoWrapper = styled.div`
  background: linear-gradient(180deg, #141414 0%, #484848 100%);
  display: flex;
  padding: 18px 16px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  max-width: 328px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: 28px 16px;
    justify-content: center;
    height: 300px;
  }
`;

const InfoHeaderText = styled(S3)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${S1Styles}
    color: ${({ theme }) => theme.colors.neutral1};
  }
`;

const InfoText = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
  margin-bottom: 10px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${P1Styles}
    color: ${({ theme }) => theme.colors.neutral1};
    margin-bottom: 16px;
  }
`;

const SubText = styled(S2)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    ${S1Styles}
    color: ${({ theme }) => theme.colors.neutral1};
    font-size: 20px;
  }
`;

const InfoContentWrapper = styled.div``;

const CarouselWrapper = styled.div``;

const GTShopMasthead: React.FC = () => {
  const gtCards = [
    {
      card: 'SSS Tier GT Reward',
      image: '/assets/gt/SSS-Tier_GT-Cards_Creator-Page.png',
    },
    {
      card: 'S Tier GT Reward',
      image: '/assets/gt/S-Tier_GT-Cards_Creator-Page.png',
    },
    {
      card: 'A Tier GT Reward',
      image: '/assets/gt/A-Tier_GT-Cards_Creator-Page.png',
    },
    {
      card: 'B Tier GT Reward',
      image: '/assets/gt/B-Tier_GT-Cards_Creator-Page.png',
    },
    {
      card: 'C Tier GT Reward',
      image: '/assets/gt/C-Tier_GT-Cards_Creator-Page.png',
    },
  ];
  return (
    <MastheadWrapper data-testid="gt-masthead">
      <MastheadContainer>
        <InfoWrapper>
          <InfoContentWrapper>
            <InfoHeaderText>✨ FIRST TIME EVER!! ✨</InfoHeaderText>
            <InfoText>Help unlock limited edition Gorilla Tag Holographic Cards with every purchase!</InfoText>
            <SubText>100 cards have been signed by Lemming, the creator of Gorilla Tag!</SubText>
          </InfoContentWrapper>
        </InfoWrapper>
        <CarouselWrapper>
          <GTShopMastheadCarousel>
            {gtCards.map((gtCard) => (
              <Image src={gtCard.image} alt={gtCard.card} width={1000} height={1000} />
            ))}
          </GTShopMastheadCarousel>
        </CarouselWrapper>
      </MastheadContainer>
    </MastheadWrapper>
  );
};
export default GTShopMasthead;
