import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import posthog from 'posthog-js';
import { Button, utils } from '@makeship/core';
import { CartEventLocations, ProductStage, ProductType } from '../../../../types/common';
import { Caption, H3, P2, S2, S3 } from '../../../Typography';
import { useStore } from '../../../../store';
import { addProducts, showCart } from '../../../../store/store.actions';
import { AccordionArrow } from '../../../Accordion';
import config from '../../../../../config.json';
import { analyticsTrackAddToCart, analyticsTrackViewCart } from '../../../../utils/analytics';
import { getFormattedAmount } from '../../../../utils/accounting';
import { Checkbox } from '../../../Input';
import { cartLinesAdd, createCart } from '../../../../api/cart';
import Loader from '../../../Loader';
import { getProductIDFromShopifyGID, getProductType } from '../../../../utils/product';
import SizeSelector from '../../../SizeSelector';
import { scrollToSizeError } from '../commonFunctions';
import {
  addVariantSelectionErrorIfNotAlreadyPresent,
  doesErrorsContainProductID,
  getVariantIDErrorFromProductID,
} from '../../../../utils/errors';
import { ErrorType, VariantSelectionError } from '../../../../types/errors';
import { transformHtml } from '../../../../utils/htmr';

const { elements } = config;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProductPrice = styled(S2)<{ sale: boolean; isDetailItem?: boolean }>`
  ${({ sale }) =>
    sale &&
    `margin-right: 8px;
    text-decoration: line-through;
    text-decoration-thickness: 3px;
  `}
  color: ${({ sale, theme }) =>
    sale ? utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark) : theme.colors.neutral7};
  font-weight: ${({ isDetailItem }) => isDetailItem && '400'};
`;

const Strikethrough = styled.span`
  text-decoration: line-through;
`;

const ProductSalePrice = styled(S2)`
  color: ${({ theme }) => theme.colors.error};
  text-transform: uppercase;
`;

const SalePriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
`;

const CompleteCollectionContainer = styled.div<{ isOnProductPage: boolean }>`
  ${({ theme, isOnProductPage }) =>
    isOnProductPage
      ? `
  border-top: 1px solid: ${utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  margin-top: 24px; padding-top: 20px;
  `
      : `
  border-radius: 12px;
  border: 1px solid ${utils.hexToRGBA('#14141426', 10)};
  padding: 10px 12px;
  background-color: ${utils.hexToRGBA(theme.colors.neutral1, 90)};
  `};
`;

const CompleteCollectionCollapsedWrapper = styled.div``;

const CompleteCollectionExpandedWrapper = styled.div<{ error: boolean }>`
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.transparent)};
  border-radius: 8px;
  margin: 20px 0;
`;

const CompleteCollectionGroupedProductsRow = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ index }) => (index > 0 ? `margin-top: 20px;` : ``)};
`;

const ProductThumbnail = styled.div<{
  selected: boolean;
  isOnBottomRow: boolean;
  isCreatorBranded?: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: center;
  padding: 4px;
  margin: ${({ isOnBottomRow }) => (isOnBottomRow ? '0 2px' : '0 8px')};
  cursor: pointer;
  border-radius: 8px;
  max-width: 84px;
  border: ${({ isCreatorBranded, theme, selected }) =>
    isCreatorBranded
      ? selected
        ? `1px solid ${theme.colors.neutral7}`
        : `1px solid ${theme.colors.neutral3}`
      : selected
      ? `1px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.neutral3}`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: initial;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    flex-direction: row;
    column-gap: 4px;
    justify-content: space-between;
    margin: ${({ isOnBottomRow }) => (isOnBottomRow ? '0 4px' : '0 10px')};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    :hover {
      border: 1px solid
        ${({ isCreatorBranded, theme }) => (isCreatorBranded ? theme.colors.neutral7 : theme.colors.primary)};
    }
    width: 147px;
    height: 117px;
  }
`;

const ProductThumbnailImageWrapper = styled.div`
  align-self: center;
  margin-top: 13px;
  width: 78px;
  height: 78px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 86px;
    height: 86px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-top: 0;
  }
`;

const DetailImageWrapper = styled.div`
  margin: 0px 12px;
  min-width: 108px;
`;

const CompleteCollectionDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-end;
`;

const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const CollectionDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  padding: 14px 0;
  cursor: pointer;
  align-items: center;
`;

const CollectionItemDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PlusIcon = styled(H3)<{ isCreatorBranded?: boolean }>`
  color: ${({ theme, isCreatorBranded }) => (isCreatorBranded ? theme.colors.neutral7 : theme.colors.primary)};
`;

const TotalPriceWrapper = styled.div<{ visible: boolean; location?: string }>`
  display: flex;
  flex-direction: column;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  margin-top: ${({ location }) => location === 'bottom' && '20px'};
`;

const TotalPriceLabel = styled(S2)<{ isCreatorBranded?: boolean }>`
  color: ${({ theme, isCreatorBranded }) => (isCreatorBranded ? theme.colors.neutral7 : theme.colors.primaryDark)};
`;

const PDPLink = styled.a`
  font-size: 12px;
  text-decoration: underline;

  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  :visited {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

const ErrorMessage = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
  margin-top: -16px;
  margin-bottom: 16px;
`;

const StyledLoader = styled(Loader)`
  width: 9em;
  height: 9em;
  margin: 0 auto;
  padding: 0;
`;

const AddToCartButton = styled(Button.Primary)<{
  uiDisabled: boolean;
  isCreatorBranded?: boolean;
}>`
  width: 100%;
  ${({ isCreatorBranded, theme }) =>
    isCreatorBranded &&
    `
      background: ${theme.colors.neutral7};
      &:hover {
        background: ${theme.colors.neutral6};
      }
    `}
`;

const CompleteCollectionTitle = styled(S2)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    font-size: 18px;
  }
`;

const ViewDetailsText = styled(P2)`
  margin-right: 8px;
`;

const ExpandedCollectionTitle = styled(S2)``;

const SaleColour = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

const ProductCollectionCollageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.cloud, theme.alpha.light)};
  border: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.midnight, theme.alpha.extraLight)};
`;

const ProductCollectionCollageImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: 2px 4px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 90px;
    height: 90px;
  }
`;

const BundleNotice = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(95deg, #fff 0.12%, #ffe7ab 100.12%);
  border: 1px solid var(--opacity-60, rgba(20, 20, 20, 0.6));
`;

const FreeShippingCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral5};
`;

type CompleteCollectionComponentProps = {
  metafields: Metafields;
  productsBySameCreator: Shopify.ProductEdge[];
  productsInSameMakeshiftCollection: Shopify.ProductEdge[];
  product: Shopify.Product | undefined;
  stage: ProductStage | undefined;
  makeshiftCollectionTitle: string | null;
  completeTheCollectionRef: React.RefObject<HTMLDivElement>;
  pinAddOnProduct?: Shopify.Product | null;
  setSelectedVariant: (variant: Shopify.ProductVariant | undefined) => void;
  selectedHoodieVariants: Shopify.ProductVariant[];
  setSelectedHoodieVariants: (variants: Shopify.ProductVariant[]) => void;
  errors: VariantSelectionError[];
  setErrors: (errors: VariantSelectionError[]) => void;
  creatorName?: string;
  isCreatorBranded?: boolean;
  isGTReward?: boolean;
  cycPromoMessage?: string;
  gtStretchGoalsMet?: boolean;
};

const CompleteCollectionComponent: React.FC<CompleteCollectionComponentProps> = ({
  metafields,
  productsBySameCreator,
  productsInSameMakeshiftCollection,
  product,
  stage,
  makeshiftCollectionTitle,
  completeTheCollectionRef,
  pinAddOnProduct,
  setSelectedVariant,
  selectedHoodieVariants,
  setSelectedHoodieVariants,
  errors,
  setErrors,
  creatorName,
  isCreatorBranded,
  isGTReward,
  cycPromoMessage,
  gtStretchGoalsMet,
}: CompleteCollectionComponentProps) => {
  const { state, dispatch } = useStore();
  const productList = useMemo(() => {
    if (product && pinAddOnProduct) {
      return [{ node: pinAddOnProduct }];
    }
    return productsBySameCreator.length > 0 ? productsBySameCreator : productsInSameMakeshiftCollection;
  }, [pinAddOnProduct, productsBySameCreator, productsInSameMakeshiftCollection, product]);

  const getCollectionProducts = () => {
    if (product) {
      return [product, ...productList.map(({ node }) => node)];
    }
    if (productsBySameCreator.length === 1 && pinAddOnProduct) {
      return [...productsBySameCreator.map(({ node }) => node), pinAddOnProduct];
    }
    return productList.map(({ node }) => node);
  };

  const getProductListMap = () => {
    if (product) {
      return [...productList.map(({ node }) => node), product];
    }
    if (productsBySameCreator.length === 1 && pinAddOnProduct) {
      return [...productsBySameCreator.map(({ node }) => node), pinAddOnProduct];
    }
    return productList.map(({ node }) => node);
  };

  const collectionProducts = getCollectionProducts();
  const productListMap = getProductListMap();

  const [selectedCollectionProducts, setSelectedCollectionProducts] = useState<Shopify.Product[]>(productListMap);
  const [viewCollectionDetails, setViewCollectionDetails] = useState(false);

  const [isCalculatingDiscount, setIsCalculatingDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<number | null>(null);
  const [tempCart, setTempCart] = useState<Shopify.Cart>();

  const isOnCreatorCollectionPage = product === undefined;

  const findProductByProductID = (id: string, variants: Shopify.ProductVariant[]) =>
    variants.find((variant) => variant.product.id === id);

  useEffect(() => {
    const updatedCollectionProducts = productListMap;
    setSelectedCollectionProducts(updatedCollectionProducts);

    if (updatedCollectionProducts.length > 0) {
      setIsCalculatingDiscount(true);
      createCart()
        .then(async (payload) => {
          if (payload && payload.cart) {
            const tempCart = (await cartLinesAdd(
              payload.cart.id,
              updatedCollectionProducts.map((product) => product.variants.edges[0].node as Shopify.ProductVariant),
              1,
            ).then((data) => data?.cart)) as Shopify.Cart;
            setDiscountPrice(tempCart.cost.subtotalAmount.amount);
            setTempCart(tempCart);
          }
        })
        .finally(() => setIsCalculatingDiscount(false));
    }
  }, [product]);

  const handleCollectionProductSelection = (product: Shopify.Product) => {
    // Remove this variant from the errors and update errors
    const removedVariantFromError = errors?.filter((error) => error.productID !== product.id);
    setErrors(removedVariantFromError);
    if (selectedCollectionProducts.includes(product)) {
      setSelectedCollectionProducts(selectedCollectionProducts.filter((item) => item.id !== product.id));
      // Unselects hoodie size
      if (
        selectedCollectionProducts.some(
          (product) =>
            getProductType(product.productType) === ProductType.Hoodie ||
            getProductType(product.productType) === ProductType.Crewneck,
        )
      ) {
        setSelectedHoodieVariants(selectedHoodieVariants.filter((item) => item.product.id !== product.id));
        setSelectedVariant(undefined);
      }
    } else {
      setSelectedCollectionProducts([...selectedCollectionProducts, product]);
    }

    posthog.capture('select_complete_collection_product', {
      productID: getProductIDFromShopifyGID(product.id),
      selectedCollectionProducts: selectedCollectionProducts.map(({ id }) => getProductIDFromShopifyGID(id)),
      productList: collectionProducts.map((collectionProduct) =>
        getProductIDFromShopifyGID(collectionProduct?.id || ''),
      ),
      discountPrice: Number(discountPrice),
      totalPrice: selectedCollectionProducts.reduce(
        (acc, product) => acc + Number(product.variants.edges[0].node.price.amount),
        0,
      ),
      isPlushPinBundleAvailable: !!pinAddOnProduct,
    });
  };

  // Used with hoodie size selection to select the product when size is picked
  const handleSizeSelectionProductSelection = (product: Shopify.Product) => {
    // Remove this variant from the errors and update errors
    const removedVariantFromError = errors?.filter((error) => error.productID !== product.id);
    setErrors(removedVariantFromError);
    if (!selectedCollectionProducts.includes(product)) {
      setSelectedCollectionProducts([...selectedCollectionProducts, product]);
    }
  };

  const handleAddCollectionProductsToCart = () => {
    if (selectedCollectionProducts.length === 0) {
      const selectVariantError = addVariantSelectionErrorIfNotAlreadyPresent(
        errors,
        product?.id ?? '',
        ErrorType.VariantSelectionMissing,
      );

      setErrors(selectVariantError);
      return;
    }

    const unselectedHoodieSizeProduct = selectedCollectionProducts.filter(
      (product) =>
        (getProductType(product.productType) === ProductType.Hoodie ||
          getProductType(product.productType) === ProductType.Crewneck) &&
        !findProductByProductID(product.id, selectedHoodieVariants),
    );

    if (unselectedHoodieSizeProduct.length > 0) {
      setViewCollectionDetails(true);

      const newErrorList = [...errors];

      // Add error for every hoodie missing size selection
      unselectedHoodieSizeProduct.forEach((product) => {
        const doesHoodieAlreadyHaveError = errors.find((error) => error.productID === product.id);
        if (!doesHoodieAlreadyHaveError) {
          newErrorList.push({ productID: product.id, error: ErrorType.HoodieSizeMissing });
        }
      });

      setErrors(newErrorList);

      setTimeout(() => {
        scrollToSizeError();
      }, 0); // Delay to ensure the re-render happens

      return;
    }

    const collectionProductVariants = selectedCollectionProducts
      .map((product) =>
        getProductType(product.productType) !== ProductType.Hoodie ||
        getProductType(product.productType) !== ProductType.Crewneck
          ? product.variants.edges[0].node
          : findProductByProductID(product.id, selectedHoodieVariants) || product.variants.edges[0].node,
      )
      .filter((variant) => variant !== undefined);
    addProducts(state.cart, 1, collectionProductVariants).then((action) => dispatch(action));
    dispatch(showCart());
    selectedCollectionProducts.forEach((product) => {
      analyticsTrackAddToCart(
        product,
        1,
        state.user,
        CartEventLocations.CompleteTheCollection,
        product.variants.edges[0].node,
        metafields.campaignID,
        !!pinAddOnProduct,
      );
      analyticsTrackViewCart(
        state.cart.shopifyCart?.lines.edges,
        state.cart.shopifyCart?.cost.subtotalAmount.amount,
        state.user,
        CartEventLocations.CartPopout,
      );
    });
  };

  const handleViewDetailClick = () => {
    setViewCollectionDetails(!viewCollectionDetails);
    posthog.capture('view_collection_details', {
      productID: getProductIDFromShopifyGID(product?.id ?? ''),
      selectedCollectionProducts: selectedCollectionProducts.map(({ id }) => getProductIDFromShopifyGID(id)),
      productList: collectionProducts.map((collectionProduct) =>
        getProductIDFromShopifyGID(collectionProduct?.id ?? ''),
      ),
      discountPrice: Number(discountPrice),
      totalPrice: selectedCollectionProducts.reduce(
        (acc, product) => acc + Number(product.variants.edges[0].node.price.amount),
        0,
      ),
      isPlushPinBundleAvailable: !!pinAddOnProduct,
    });
  };

  const renderSingleProductPrice = (
    product: Shopify.Product,
    tempCart: Shopify.Cart | undefined,
    isSelected?: boolean,
    isDetailItem?: boolean,
    hasPinBundleSale?: boolean | null | undefined,
  ): React.ReactNode => {
    const selectedVariant = product?.variants.edges[0].node;
    const productInTempCart = tempCart?.lines.edges.find((item) => item.node.merchandise?.id === selectedVariant?.id);
    const amountToBeDiscounted = productInTempCart?.node.discountAllocations[0]?.discountedAmount.amount;

    const renderSalePrice = (regularPrice: string, discountedPrice: string) => (
      <ProductWrapper>
        <PriceRow>
          <SalePriceWrapper>
            <ProductPrice sale isDetailItem={isDetailItem}>
              ${getFormattedAmount(regularPrice)}
            </ProductPrice>
            <ProductSalePrice>${getFormattedAmount(discountedPrice)}</ProductSalePrice>
          </SalePriceWrapper>
        </PriceRow>
      </ProductWrapper>
    );

    const renderRegularPrice = (price: string) => (
      <PriceRow>
        <ProductPrice sale={false} isDetailItem={isDetailItem}>
          ${getFormattedAmount(price)}
        </ProductPrice>
      </PriceRow>
    );

    if (productInTempCart && amountToBeDiscounted && isSelected && hasPinBundleSale && amountToBeDiscounted > 0) {
      const discountedPrice = (selectedVariant?.price.amount - amountToBeDiscounted).toString();
      return renderSalePrice(selectedVariant?.price.amount, discountedPrice);
    }

    if (selectedVariant?.compareAtPrice?.amount) {
      return renderSalePrice(selectedVariant.compareAtPrice.amount, selectedVariant.price.amount);
    }

    return renderRegularPrice(selectedVariant?.price.amount);
  };

  const renderTotalProductPrice = (
    products: Shopify.Product[],
    tempCart: Shopify.Cart | undefined,
    isDetailItem?: boolean,
    isCalculatingDiscount?: boolean,
  ): React.ReactNode => {
    const rawPriceData = products.map(({ variants }) => {
      const selectedVariant = variants.edges[0].node;
      const productInTempCart = tempCart?.lines.edges.find((item) => item.node.merchandise?.id === selectedVariant?.id);
      const amountToBeDiscounted = productInTempCart?.node.discountAllocations[0]?.discountedAmount.amount;

      const regularPrice = selectedVariant.compareAtPriceV2?.amount || selectedVariant.price.amount;
      const discountedPrice = amountToBeDiscounted
        ? (Number(selectedVariant.price.amount) - Number(amountToBeDiscounted)).toFixed(2)
        : regularPrice;

      return {
        regularPrice: Number(regularPrice),
        discountedPrice: Number(discountedPrice),
      };
    });

    // Calculate the total price and total discounted price
    const totalRegularPrice = rawPriceData.reduce((total, item) => total + item.regularPrice, 0).toFixed(2);
    const totalDiscountedPrice = rawPriceData.reduce((total, item) => total + item.discountedPrice, 0).toFixed(2);

    const hasSale = !isCalculatingDiscount && totalDiscountedPrice < totalRegularPrice;

    const renderSalePrice = (regularPrice: string, discountedPrice: string) => (
      <ProductWrapper>
        <PriceRow>
          <SalePriceWrapper>
            <ProductPrice sale isDetailItem={isDetailItem}>
              ${getFormattedAmount(regularPrice)}
            </ProductPrice>
            <ProductSalePrice>${getFormattedAmount(discountedPrice)}</ProductSalePrice>
          </SalePriceWrapper>
        </PriceRow>
      </ProductWrapper>
    );

    const renderRegularPrice = (price: string) => (
      <PriceRow>
        <ProductPrice sale={false} isDetailItem={isDetailItem}>
          ${getFormattedAmount(price)}
        </ProductPrice>
      </PriceRow>
    );

    return hasSale ? renderSalePrice(totalRegularPrice, totalDiscountedPrice) : renderRegularPrice(totalRegularPrice);
  };

  const renderProductPrice = (
    products: Shopify.Product[],
    tempCart: Shopify.Cart | undefined,
    isSelected?: boolean,
    isDetailItem?: boolean,
    hasPinBundleSale?: boolean | null | undefined,
    isCalculatingDiscount?: boolean,
  ): React.ReactNode => {
    if (products.length === 1) {
      return renderSingleProductPrice(products[0], tempCart, isSelected, isDetailItem, hasPinBundleSale);
    }

    return renderTotalProductPrice(products, tempCart, isDetailItem, isCalculatingDiscount);
  };

  const renderCompleteCollectionTitle = () => {
    if (isGTReward && !gtStretchGoalsMet && creatorName) {
      return `Complete your ${creatorName} Collection to help unlock exclusive rewards! 🎉`;
    }
    if (isGTReward && !gtStretchGoalsMet && metafields.creator) {
      return `Complete your ${metafields.creator} Collection to help unlock exclusive rewards! 🎉`;
    }
    if (
      metafields.completeCollectionDiscountPercent &&
      (metafields.creator !== undefined || makeshiftCollectionTitle !== null || creatorName)
    ) {
      return (
        <>
          <SaleColour>Get up to {metafields.completeCollectionDiscountPercent}% off</SaleColour> when you add all of{' '}
          {metafields.creator || makeshiftCollectionTitle || creatorName}&apos;s newest drops to your collection!
        </>
      );
    }

    if (collectionProducts.length > 6) {
      return `Add all of ${
        makeshiftCollectionTitle || metafields.creator || creatorName
      }'s newest drops to your collection!`;
    }

    if (productsBySameCreator.length === 0 && makeshiftCollectionTitle !== null) {
      return `Complete your ${makeshiftCollectionTitle} Collection!`;
    }

    if (creatorName) return `Complete your ${creatorName} Collection!`;
    return `Complete your ${metafields.creator} Collection!`;
  };

  // Split product list into arrays of 3 products
  const calculateGroupsOfThrees = (collectionProducts: Shopify.Product[]) => {
    const groupedProductsInThrees: Shopify.Product[][] = [];

    // eslint-disable-next-line guard-for-in, no-plusplus
    for (let i = 0; i < collectionProducts.length; i++) {
      const groupedIndex = Math.floor(i / 3);
      if (groupedProductsInThrees[groupedIndex]) {
        groupedProductsInThrees[groupedIndex].push(collectionProducts[i]);
      } else {
        groupedProductsInThrees[groupedIndex] = [];
        groupedProductsInThrees[groupedIndex].push(collectionProducts[i]);
      }
    }
    return groupedProductsInThrees;
  };

  const renderProductsRow = (collectionProducts: Shopify.Product[] | undefined) => {
    if (!collectionProducts) return null;
    const productsGroupedInThrees = calculateGroupsOfThrees(collectionProducts);
    return productsGroupedInThrees.map((productGroup, index) => (
      <CompleteCollectionGroupedProductsRow index={index}>
        {index > 0 && <PlusIcon isCreatorBranded={isCreatorBranded}>+</PlusIcon>}
        {productGroup.map((product, innerIndex) => (
          <>
            {innerIndex > 0 && <PlusIcon isCreatorBranded={isCreatorBranded}>+</PlusIcon>}
            <ProductThumbnail
              isOnBottomRow={index > 0}
              onClick={() => handleCollectionProductSelection(product)}
              selected={selectedCollectionProducts.includes(product)}
              isCreatorBranded={isCreatorBranded}
            >
              <Checkbox
                value={selectedCollectionProducts.includes(product)}
                handleCollectionProductSelection={handleCollectionProductSelection}
                product={product}
                isCreatorBranded={isCreatorBranded}
              />
              <ProductThumbnailImageWrapper>
                <Image
                  src={product.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                  alt={product.images.edges[0]?.node.altText || 'product image'}
                  width={102}
                  height={102}
                  quality={90}
                  unoptimized
                  data-testid="product-card-image"
                />
              </ProductThumbnailImageWrapper>
            </ProductThumbnail>
          </>
        ))}
      </CompleteCollectionGroupedProductsRow>
    ));
  };

  const renderProductCollectionCollage = (collectionProducts: Shopify.Product[]) => {
    const firstTwelveProducts = collectionProducts.slice(0, 12);
    return (
      <ProductCollectionCollageWrapper>
        {firstTwelveProducts.map((product) => (
          <ProductCollectionCollageImageWrapper>
            <Image
              src={product.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
              alt={product.images.edges[0]?.node.altText || 'product image'}
              width={102}
              height={102}
              quality={90}
              unoptimized
              data-testid="product-card-image"
            />
          </ProductCollectionCollageImageWrapper>
        ))}
      </ProductCollectionCollageWrapper>
    );
  };

  const renderCollectionProducts = () => {
    if (collectionProducts.length <= 6 && collectionProducts !== undefined) {
      return renderProductsRow(collectionProducts);
    }
    return renderProductCollectionCollage(collectionProducts);
  };

  return (
    <div ref={completeTheCollectionRef}>
      <CompleteCollectionContainer isOnProductPage={!isOnCreatorCollectionPage}>
        <CompleteCollectionTitle>{renderCompleteCollectionTitle()}</CompleteCollectionTitle>
        <CompleteCollectionExpandedWrapper
          error={(errors.length > 0 && selectedCollectionProducts.length === 0) || false}
        >
          <CompleteCollectionCollapsedWrapper>
            {renderCollectionProducts()}
            {pinAddOnProduct && selectedCollectionProducts.length < 2 && (
              <BundleNotice>
                <S3>🎉 BUNDLE & SAVE</S3>
                <P2>
                  Get the matching enamel pin for <Strikethrough>$15.99</Strikethrough> <strong>$9.99*</strong> when you
                  complete your {metafields.creator && metafields.creator} collection!
                </P2>
                <FreeShippingCaption>*no extra shipping</FreeShippingCaption>
              </BundleNotice>
            )}
            {cycPromoMessage && !pinAddOnProduct && (
              <BundleNotice>
                <S3>🎉 UNLOCK FREE SHIPPING 🎉</S3>
                <P2>{transformHtml(cycPromoMessage)}</P2>
              </BundleNotice>
            )}
            <CompleteCollectionDetailRow>
              <TotalPriceWrapper
                visible={!isCalculatingDiscount && !viewCollectionDetails && selectedCollectionProducts.length > 0}
              >
                <TotalPriceLabel isCreatorBranded={isCreatorBranded}>Total Price:</TotalPriceLabel>
                {renderProductPrice(selectedCollectionProducts, tempCart)}
              </TotalPriceWrapper>
              <DetailWrapper onClick={() => handleViewDetailClick()}>
                <ViewDetailsText>{collectionProducts.length > 6 ? 'View Collection' : 'View Details'}</ViewDetailsText>
                <AccordionArrow
                  $visible={viewCollectionDetails}
                  src="/assets/icons/dropdown-icon.svg"
                  width={24}
                  height={12}
                />
              </DetailWrapper>
            </CompleteCollectionDetailRow>
          </CompleteCollectionCollapsedWrapper>

          {viewCollectionDetails && (
            <CollectionDetailsContainer>
              {collectionProducts.map((product, index) => (
                <DetailRow>
                  <Checkbox
                    value={selectedCollectionProducts.includes(product)}
                    handleCollectionProductSelection={handleCollectionProductSelection}
                    product={product}
                    isCreatorBranded={isCreatorBranded}
                  />
                  <DetailImageWrapper onClick={() => handleCollectionProductSelection(product)}>
                    <Image
                      src={product.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                      alt={product.images.edges[0]?.node.altText || 'product image'}
                      width={100}
                      height={100}
                      quality={90}
                      unoptimized
                      data-testid="product-card-image"
                    />
                  </DetailImageWrapper>
                  <CollectionItemDetailWrapper>
                    <ExpandedCollectionTitle>{product.title}</ExpandedCollectionTitle>
                    {renderProductPrice(
                      [product],
                      tempCart,
                      selectedCollectionProducts.includes(product),
                      true,
                      pinAddOnProduct && selectedCollectionProducts.length > 1,
                    )}
                    {(getProductType(product.productType) === ProductType.Hoodie ||
                      getProductType(product.productType) === ProductType.Crewneck) && (
                      <SizeSelector
                        errors={errors}
                        setErrors={setErrors}
                        variants={product.variants.edges}
                        setSelectedVariants={setSelectedHoodieVariants}
                        product={product}
                        inCompleteTheCollection
                        selectedVariants={selectedHoodieVariants}
                        handleSizeSelectionProductSelection={handleSizeSelectionProductSelection}
                        isCreatorBranded={isCreatorBranded}
                      />
                    )}
                    {product == pinAddOnProduct && selectedCollectionProducts.length > 1 && (
                      <Caption>Bundle & Save discount applied!</Caption>
                    )}
                    {(index !== 0 || isOnCreatorCollectionPage) && product !== pinAddOnProduct && (
                      <PDPLink
                        href={`/products/${product.handle}`}
                        onClick={(e) => {
                          posthog.capture('view_collection_product_details', {
                            selectedProductID: getProductIDFromShopifyGID(product.id),
                            selectedCollectionProducts: selectedCollectionProducts.map(({ id }) =>
                              getProductIDFromShopifyGID(id),
                            ),
                            productList: collectionProducts.map((collectionProduct) =>
                              getProductIDFromShopifyGID(collectionProduct.id),
                            ),
                            discountPrice: Number(discountPrice),
                            totalPrice: selectedCollectionProducts.reduce(
                              (acc, product) => acc + Number(product.variants.edges[0].node.price.amount),
                              0,
                            ),
                          });
                          e.stopPropagation();
                        }}
                      >
                        View Details
                      </PDPLink>
                    )}
                  </CollectionItemDetailWrapper>
                </DetailRow>
              ))}
              {!isCalculatingDiscount && (
                <TotalPriceWrapper visible={selectedCollectionProducts.length > 0} location="bottom">
                  <TotalPriceLabel isCreatorBranded={isCreatorBranded}>Total Price:</TotalPriceLabel>
                  {renderProductPrice(selectedCollectionProducts, tempCart)}
                </TotalPriceWrapper>
              )}
            </CollectionDetailsContainer>
          )}
        </CompleteCollectionExpandedWrapper>
        {doesErrorsContainProductID(errors, product?.id) && selectedCollectionProducts.length === 0 && (
          <ErrorMessage>{getVariantIDErrorFromProductID(errors, product?.id)?.error}</ErrorMessage>
        )}
        {isCalculatingDiscount ? (
          <StyledLoader />
        ) : (
          <AddToCartButton
            id={elements.addToCartButton}
            onClick={() => handleAddCollectionProductsToCart()}
            uiDisabled={stage === ProductStage.Draft}
            disabled={stage === ProductStage.Draft}
            isCreatorBranded={isCreatorBranded}
          >
            Complete My Collection!
          </AddToCartButton>
        )}
      </CompleteCollectionContainer>
    </div>
  );
};

export default CompleteCollectionComponent;
